.socialLogin_button {
  border-radius: 4px;
  width: 36% !important;
  margin: 10px;
  text-transform: uppercase;
  font-weight: 700;
  border: 2px solid var(--redContinente);
  font-family: "Open Sans";
  height: 42px;
  padding: 0;
  min-width: 100px;
}

.socialLogin_button:hover {
  color: var(--white);
  background-color: var(--darkRedContinente);
  border-color: var(--darkRedContinente);
  opacity: 1;
}

.defaultButton {
  background-color: var(--redContinente);
  color: var(--white);
  width: 100%;
}

.defaultButtonInverted {
  background-color: var(--white);
  color: var(--redContinente);
  width: 100%;
  border: 2px solid var(--redContinente);
}

.defaultButtonInverted:hover {
  background-color: var(--redContinente);
  border: 2px solid var(--redContinente);
}
