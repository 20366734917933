.input-fields {
  height: 48px;
  box-sizing: border-box;
  border: 1px solid var(--greyCredentials);
  border-radius: 8px;
  background-color: var(--white);
  padding-left: 20px;
  padding-top: 21px;
  padding-bottom: 14px;
  padding-right: 35px;
  font-size: 15px;
  margin-left: 24px;
  margin-right: 24px;
}

input:focus {
  outline: none;
  border: 1px solid var(--neutral2);
}

.input:-internal-autofill-selected {
  background-color: var(--white) !important;
}

.label-input {
  height: 14px;
  margin-top: -6px !important;
  margin-left: 40px;
  color: var(--greyCredentials);
  font-family: "Open Sans";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  background-color: var(--white);
  padding-left: 5px;
  padding-right: 5px;
}

.container-input .filled {
  transform: translate(0, 1px) scale(0.8);
}

.container-input {
  position: relative;
  display: flex;
  flex-direction: column;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.container-input label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 15px;
}

.container-input:focus-within label {
  transform: translate(0, 1px) scale(0.8);
  color: var(--neutral);
}

.input-outline-eye {
  align-items: center;
  display: block;
}

.input-outline-eye svg {
  cursor: pointer;
}

.input-outline-eye .input-icon-eye {
  position: absolute;
  right: 40px;
  top: 13px;
  color: var(--neutral2);
}

.input-check {
  position: absolute;
  right: 20px;
  top: 14.67px;
  width: 15px;
}
