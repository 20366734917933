:root {
  --redContinente: #e20714;
  --darkRedContinente: #7f1215;
  --white: #ffffff;
  --black: #000000;
  --redLightSONAE: #ee7171;
  --lightGrey: #e9e9e9;
  --textGrey: #32312d;
  --redCredentials: #eb0203;
  --greyCredentials: #cccccc;
  --darkgrayCredentials: #999999;
  --neutral: #333333;
  --neutral2: #7f7f7f;
  --mdc-theme-primary: #eb0203;
  --border-neutral: #f5f5f5;
  --text-btn-disable: #9fa0a0;
}

#root {
  height: 100%;
  min-height: 100vh;
}
