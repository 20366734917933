.atoms-assets-images-novo-login {
  width: 42px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
  display: block;
}

.group {
  margin-top: 30px;
  margin-bottom: 21.58px;
  width: 230px;
}

.faq-contextual-information {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 37px;
}

.faq-title {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 35.15px;
  margin-bottom: 25px;
}

.line {
  height: 5px;
  width: 36px;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.5);
  margin-top: 6px;
}
