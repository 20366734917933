.mail {
  width: 120px;
  display: block;
  margin: auto;
}

.email-verification-resend-code {
  height: 19px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 24px;
}

.email-verification-resend-code.link {
  color: var(--black);
  cursor: pointer;
  margin-top: 0px;
}
