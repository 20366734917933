.mantra-button {
  margin-left: 73.5px;
  margin-right: 73.5px;
  height: 44px;
  width: 228px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  padding: 8px, 16px, 8px, 16px;
}

/*  Primary Button CSS */
.mantra-primary-button {
  border: 2px solid var(--cnt-primary);
  background-color: var(--cnt-primary);
}

.mantra-primary-button:hover {
  background-color: var(--cnt-primary-700);
  border-color: var(--cnt-primary-700);
  opacity: 1;
}

.mantra-primary-button:disabled {
  background-color: var(--neutral-5);
  opacity: 1;
  border-color: var(--neutral-5);
}

.mantra-primary-button-text {
  color: var(--neutral-0);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  display: block;
  margin: auto;
  letter-spacing: -0.24px;
}

/* Secondary button  */

.mantra-secondary-button {
  border: 2px solid var(--cnt-primary);
  background-color: var(--neutral-0);
}

.mantra-secondary-button:hover {
  background-color: var(--cnt-primary-50);
  border-color: var(--cnt-primary-700);
  opacity: 1;
}

.mantra-secondary-button:disabled {
  background-color: var(--neutral-0);
  opacity: 1;
  border-color: var(--neutral-10);
}

.mantra-secondary-button-text {
  color: var(--cnt-primary);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  display: block;
  margin: auto;
  letter-spacing: -0.24px;
}

.mantra-button-text-disabled {
  color: var(--neutral-40);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.buttons-primary-large {
  margin-left: 73.5px;
  margin-right: 73.5px;
  height: 44px;
  width: 280px;
  border-radius: 8px;
  border: 2px solid var(--cnt-primary);
  background-color: var(--cnt-primary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  padding: 8px, 16px, 8px, 16px;
}
