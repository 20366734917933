.alert-real-otps-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 68px;
  gap: 10px;

  width: 100%;
  height: 100%;

  background-color: #e4efe7;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  top: 0;
  z-index: 10;
  opacity: 0.8;

  animation: flash 0.7s ease infinite alternate;
}

.alert-real-otps-container.success {
  background-color: #5cb85c;
}

.alert-real-otps-container.error {
  background-color: #d9534f;
}

.alert-real-otps-container.warning {
  background-color: #fdc600;
}

.alert-real-otps-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
}

.alert-real-otps-text.success {
  color: #064911;
}

.alert-real-otps-text.error {
  color: #fff;
}

.alert-real-otps-text.warning {
  color: #525223;
}

.alert-real-otps-container > button {
  background-color: transparent;
  border: none;
  color: white;
}

@keyframes flash {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.8;
  }
}
