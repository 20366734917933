.group_title {
  color: var(--redContinente);
  font-family: "Open Sans";
  font-size: 26px;
  font-weight: normal;
  text-align: center;
  text-decoration: underline 1.2px;
  margin-top: 26px;
}
.group_sub-title {
  color: var(--redContinente);
  font-family: "Open Sans";
  font-size: 15px;
  margin-top: 32px;
  font-weight: bold;
}

.group_info {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 12px;
  text-align: left;
}

.group_message {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 15px;
  margin-top: 1px;
  text-decoration: underline;
  text-align: center;
}

.group_param {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
}

.group_box {
  margin-top: 0px;
}
