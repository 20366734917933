.continente-icon {
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  display: block;
}

.sc-section-title {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 26px;
  font-weight: bold;
  line-height: 36px;
  margin: 40px 24px 0 24px;
  padding: 0 24px;
}

.sc-section-subTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0 24px;
  padding: 0 24px;
}

.sc-contextual-information-header {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 0px;
  text-align: center !important;
}

.subtitle-text {
  padding-bottom: 14px;
}

.text-align-center {
  text-align: center;
}

.loading-bar {
  height: 4px;
}

.sc-back-icon {
  height: 32px;
  position: relative;
  top: 19.67px;
  left: 24px;
  cursor: pointer;
}

.sc-skip-span {
  height: 32px;
  position: relative;
  top: 26.67px;
  float: right;
  color: var(--redCredentials);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  margin-right: 24px;
}

.helpLink {
  height: 32px;
  position: relative;
  top: 26.67px;
  float: right;
  color: black;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  margin-right: 24px;
}
