:root {
  /* Mantra Colors */
  --cnt-primary: #eb0203;
  --cnt-primary-50: #fdf2f2;
  --cnt-primary-700: #b60202;
  --neutral-0: #ffffff;
  --neutral-5: #f5f5f5;
  --neutral-10: #ebebeb;
  --neutral-40: #9fa0a0;
}
