.input-validator {
  box-sizing: border-box;
  height: 48.7px;
  width: 48.7px;
  border: 2px solid var(--greyCredentials);
  border-radius: 8px;
  background-color: var(--white);
  margin-right: 6.96px;
  text-align: center;
  color: var(--black);
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}
.input-validator:focus {
  border: 1px solid var(--neutral2) !important;
  background-color: rgba(51, 51, 51, 0.05);
}
