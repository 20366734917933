.error-input {
  height: 65px;
}

.error-input .error-message {
  color: var(--redContinente);
  font-weight: 400;
  font-size: 11px;
}

.error-input .form-control.error {
  border-color: var(--redContinente);
}
