#socialLogin {
  background-color: white;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.socialLogin_body_margin {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.socialLogin_body {
  background-color: var(--white);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.socialLoginConsent {
  height: 250px;
  overflow: auto;
  /*margin-left: 20px;
    margin-right: 20px;*/
}

.socialLogin_inputs {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.socialLogin_reminder {
  width: 80%;
  font-size: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.socialLogin_buttons {
  text-align: center;
}

.personalDataList {
  margin-top: 20px;
  margin-bottom: 20px;
}

.personalData {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.consent_info {
  margin-top: 20px;
  margin-bottom: 20px;
}

.simplebar-scrollbar:before {
  left: 0px;
  right: 0px;
}

.simplebar-track.simplebar-vertical {
  background: var(--lightGrey);
  border-radius: 9px;
  width: 5px;
}

.consents-text {
  text-align: justify;
}

@media screen and (max-width: 320px) {
  .socialLogin_body {
    width: 65% !important;
  }

  .socialLogin_body_text {
    font-size: 12px;
  }
}

@media screen and (max-width: 376px) and (min-width: 321px) {
  .socialLogin_body {
    width: 55% !important;
  }

  .socialLogin_body_text {
    font-size: 12px;
  }
}

@media screen and (max-width: 426px) and (min-width: 376px) {
  .socialLogin_body {
    width: 55% !important;
  }

  .socialLogin_body_text {
    font-size: 12px;
  }
}

@media screen and (max-width: 769px) and (min-width: 426px) {
  .socialLogin_body {
    width: 40% !important;
  }

  .socialLogin_body_text {
    font-size: 12px;
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .socialLogin_body {
    width: 40% !important;
  }

  .socialLogin_body_text {
    font-size: 15px;
  }
}

@media screen and (max-width: 1441px) and (min-width: 1025px) {
  .socialLogin_body {
    width: 35% !important;
  }

  .socialLogin_body_text {
    font-size: 15px;
  }
}

@media screen and (min-width: 1441px) {
  .socialLogin_body {
    width: 35% !important;
  }

  .socialLogin_body_text {
    font-size: 15px;
  }
}
