.react-modal-sheet-container {
  border-top-right-radius: 24px !important;
  border-top-left-radius: 24px !important;
}

.react-modal-sheet-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.line {
  height: 5px;
  width: 36px;
  border-radius: 4px;
  background-color: rgba(211, 211, 211, 0.5);
  margin-top: 6px;
}
