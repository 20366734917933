.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Open Sans";
  cursor: pointer;
}

.button-as-link:hover {
  text-decoration: underline;
}

.button-as-link {
  height: 19px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 88.3px;
  color: var(--black);
  cursor: pointer;
  text-decoration-line: none;
}

.terms-and-conditions-checkbox-div {
  position: relative;
  top: -30px;
  left: 10px;
}
