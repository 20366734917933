.show {
  display: block;
}

.hide {
  display: none;
}

#continentecredentialsloading {
  position: fixed;
  top: 4px;
  left: 0;
  background-color: #fff;
  z-index: 9999;
  opacity: 0.5;
  min-height: 100%;
  width: 100%;
}

.loading-bar {
  position: absolute;
  min-width: 100%;
  padding: 0 2px;
}
