.loyalty-card-advantages-information {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 37px;
}

.loyalty-card-advantages-title {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 35.15px;
  margin-bottom: 25px;
}
