.modal-continente-credentials {
  background-color: var(--white);
  width: 458px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  margin-bottom: auto;
  display: block;
}

.rectangle-copy {
  height: 20px;
  width: 100%;
  background-color: var(--redCredentials);
  display: block;
  margin: auto;
}

.form-check {
  margin-top: 20px;
  margin-left: 24px;
  margin-right: 24px;
}

.form-check-center {
  margin-left: auto;
  margin-right: auto;
  width: 66%;
}

.form-check-label {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.form-check-group {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 28px;
  position: relative;
}

.form-group {
  display: inline-block;
  margin-left: 25.5px;
  margin-right: 25.5px;
}

.contextual-information {
  color: var(--black);
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 61px;
}

.more-information {
  height: 22px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}

.more-information.link {
  color: var(--black);
  text-decoration: none;
  margin-left: 5px;
}

.more-information.contextual-information {
  text-align: center;
}

.more-information-copy {
  height: 24px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  display: inline;
  margin-left: 16px;
}

.more-information-copy.link {
  color: var(--black);
  text-decoration: none;
  margin-left: 5px;
}

.loyalty-card-img {
  margin-bottom: 46px;
  width: 92px;
  margin-left: auto;
  margin-right: auto;
}

.loyalty-card-img-card-association {
  margin-bottom: 46px;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
}

.loyalty-card-success-img {
  margin-bottom: 63.22px;
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.div-card-association {
  width: 280px;
}

.more-information-copy2 {
  height: 22px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 12.58px;
}

.more-information-copy2.link {
  color: var(--black);
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}

.more-information-copy2.contextual-information-copy2 {
  text-align: left;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-29-58 {
  margin-top: 29.58px;
}

.form-error-message-copy2 {
  color: var(--redContinente);
  text-align: center;
  margin-top: 5px;
  height: auto;
}

.ereaser-img {
  width: 24px;
}

.div-login-password-input {
  width: 280px;
  margin-top: 39px;
}

.w250 {
  width: 250px;
}

.email-association-message {
  height: 19px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 57px;
}

.email-association-message.link {
  color: var(--black);
  cursor: pointer;
}

.phonenumber-association-message {
  height: 19px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 57px;
}

.contact-form-link {
  height: 19px;
  font-family: "Open Sans";
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 57px;
}

.validate-password-resend-code {
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 32px;
}

.validate-password-resend-code.link {
  color: var(--black);
  cursor: pointer;
}

.mobile-screens-margins {
  margin-left: 5vw;
  margin-right: 5vw;
}

.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Open Sans";
  cursor: pointer;
}

.button-as-link:hover {
  text-decoration: underline;
}

.margin-top-100 {
  margin-top: 100px;
}

.where-find-cc {
  height: 19px;
  color: var(--black);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 36px;
}

.where-find-cc .link {
  color: var(--black);
  cursor: pointer;
}

.card-not-exist {
  height: 19px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 24px;
}

.card-not-exist.link {
  color: var(--black);
  cursor: pointer;
  margin-top: 0px;
}

.mail-validated {
  width: 120px;
  display: block;
  margin: auto;
  margin-bottom: 68px;
}

.button-as-link {
  height: 19px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 88.3px;
  color: var(--black);
  cursor: pointer;
  text-decoration-line: none;
}

.what-is-link {
  height: 19px;
  color: var(--black);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 36px;
  cursor: pointer;
}

.sign-up {
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

.forgot-pin {
  height: 19px;
  color: var(--black);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 29.01px;
  cursor: pointer;
}

.imgMergeAccount {
  width: 70px;
}

.mergeOptions {
  display: flex;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 20px;
}

.mergeOptionsCol {
  align-self: center;
  text-align: left;
}

.mergeOptionsCol.image {
  width: 30%;
}

.mergeOptionsCol.text {
  width: 60%;
}

.mergeOptionsCol.option {
  width: 10%;
}

.contact-center {
  text-align: left;
}

.span-options {
  height: 20px;
  color: var(--black);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-top: 29.01px;
  cursor: pointer;
  letter-spacing: -0.24px;
}

.call-center-loyalty-card {
  width: 111.73px;
  height: 111.73px;
  margin-bottom: 40px;
}

.continente-card {
  width: 96px;
  height: 65px;
  padding: 0;
  margin-bottom: 40px;
}

.continente-card-little {
  height: 100px;
  padding: 0;
  margin-bottom: 40px;
}

.contact-center-icon {
  width: 100px;
  height: 100px;
  padding: 0;
  margin-bottom: 40px;
}

.continente-website {
  display: flex;
  flex-direction: column;
  width: auto;
  text-align: center;
  align-items: center;
  margin-top: 29px;
}

.continente-website > span {
  max-width: 100px;
  margin-top: 4px;
}

.continente-website > img {
  height: 42px;
  width: 42px;
  padding: 0;
}

.cc_max_width {
  width: 354px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .cc_max_width > * {
  padding: 0 0 25px 0;
} */

@media only screen and (max-width: 450px) {
  .full-container {
    padding-left: 0;
    padding-right: 0;
  }

  .cc_max_width {
    width: 100% !important;
  }
}

@media screen and (min-width: 376px) {
  /* .container {
    max-width: 425px;
  } */

  .welcome {
    text-align: center;
  }

  .faq-title {
    text-align: center;
  }

  .faq-contextual-information {
    text-align: center;
  }

  .what-is-pin-title {
    text-align: center;
  }

  .what-is-pin-contextual-information {
    text-align: center;
  }

  .privacy-politics-contextual-information {
    text-align: center;
  }

  .privacy-politics-title {
    text-align: center;
  }

  .terms-conditions-contextual-information {
    text-align: center;
  }

  .terms-conditions-title {
    text-align: center;
  }

  .where-to-find-card-number-contextual-information {
    text-align: center;
  }

  .where-to-find-card-number-title {
    text-align: center;
  }

  .contact-center {
    text-align: center;
  }
}

#AccountMerge .options-group .icon {
  width: auto;
}

terms-conditions-contextual-information {
  text-align: center;
}

.terms-conditions-title {
  text-align: center;
}

.where-to-find-card-number-contextual-information {
  text-align: center;
}

.where-to-find-card-number-title {
  text-align: center;
}

.password_rules_header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 14px;
}

.check-general-conditions-div {
  position: relative;
  top: -30px;
  left: 31px;
}
