.verification-code {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 12px;
}

.verification-resend-code {
  height: 19px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 88.3px;
}

.verification-resend-code.link {
  color: var(--black);
  cursor: pointer;
}

@media (max-width: 400px) {
  .verification-code {
    flex-wrap: nowrap;
  }

  .input-validator {
    width: 36px;
    height: 46px;
    font-size: 20px;
  }
}

@media (max-width: 300px) {
  .verification-code {
    flex-wrap: nowrap;
  }

  .input-validator {
    width: 26px;
    height: 36px;
    font-size: 15px;
  }
}

@media (max-width: 230px) {
  .verification-code {
    flex-wrap: nowrap;
  }

  .input-validator {
    width: 16px;
    height: 26px;
    font-size: 10px;
  }
}