body {
  font-family: "Open Sans";
  color: var(--textGrey);
  background-color: var(--white);
}

.padding-top {
  padding-top: 48px;
}

.info-link {
  display: inline-block;
  color: var(--black);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.d-flex.space-bet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.sc-image-center {
  width: 160px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
  display: block;
}

.error_message {
  margin-top: 15px;
  margin-bottom: 15px;
  color: red;
}

.alert-cicle {
  margin-top: 4px;
  height: 16px;
  width: 16px;
}

.errorLabel {
  color: var(--redCredentials);
  font-family: "Open Sans";
  font-size: 10px;
  margin-left: 4px;
  margin-top: 4px;
}

.errorLabel-blocked {
  color: var(--redCredentials);
  font-family: "Open Sans";
  font-size: 10px;
  margin-left: 4px;
}

.errorDiv {
  margin: 5px 24px 0 24px;
}

.text-align-left {
  text-align: left !important;
}
