.username-input .container-input input {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: -64px;
  margin-right: 24px;
}

.username-input .container-input label {
  margin-left: -47px;
}

.username-input .errorDiv {
  margin-left: -64px;
}

.username-input.fixed .container-input:focus-within label,
.username-input.fixed .container-input label.filled,
.username-input.transition.entering .container-input label,
.username-input.transition.entered .container-input label {
  margin-left: -47px;
}

.username-input.fixed .container-input input,
.username-input.transition.exiting .container-input input,
.username-input.transition.entering .container-input input,
.username-input.transition.entered .container-input input {
  border-left: none;
  border-radius: 0px 8px 8px 0px;
  padding-left: 5px;
  margin-left: 0px;
  margin-right: 24px;
}

.username-input.fixed .country-code-panel button,
.username-input.transition.exiting .country-code-panel button,
.username-input.transition.entered .country-code-panel button,
.username-input.transition.entering .country-code-panel button {
  border-right: none;
  border-radius: 8px 0px 0px 8px;
}

.username-input.transition .countries-dropdown {
  transition: width 200ms ease-in;
  width: 64px;
}

.username-input.transition.entering .countries-dropdown,
.username-input.transition.entered .countries-dropdown {
  width: 64px;
}

.username-input .countries-dropdown {
  margin-left: 24px;
}
