.notification-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 68px;
  gap: 10px;

  width: 100%;
  height: 50px;

  background-color: #e4efe7;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  top: 0;
}

.notification-container.success {
  background-color: #5cb85c;
}

.notification-container.error {
  background-color: #d9534f;
}

.notification-container.warning {
  background-color: #fdc600;
}

.notification-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
}

.notification-text.success {
  color: #064911;
}

.notification-text.error {
  color: #fff;
}

.notification-text.warning {
  color: #525223;
}

.notification-container > button {
  background-color: transparent;
  border: none;
  color: white;
}
