.identifier-input {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 12px;
  display: inline-flex;
  justify-content: center;
}

.identifier-digit {
  height: 87px;
  width: 50px;
  margin-right: 7px;
}

.digit {
  color: #000000;
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
}
