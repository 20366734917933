.sc-faq-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  /* position: absolute; */
  margin-left: auto;
  margin-right: 36px;
  margin: 20px 36px 0 auto;

  width: 64px;
  height: 64px;
  bottom: 32px;
  right: 24px;

  background: #eb0203;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
