.Modal {
  width: 15%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 0px;
  border-radius: 15px;
  background-color: white;
}

.OverlayModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
}

.modal-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: medium;
  width: 100%;
  text-align: center;
}

.modal-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: small;
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.divButtons {
  display: inline-flex;
  width: 100%;
}

.divButton {
  width: 50%;
  text-align: center;
  border-top: 1px solid lightgray;
}

.divButton1 {
  border-right: 1px solid lightgray;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.divButton2 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.modal-button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid white;
  color: blue;
  background-color: white;
}

@media screen and (max-width: 320px) {
  .Modal {
    width: 80%;
  }
}

@media screen and (max-width: 376px) and (min-width: 321px) {
  .Modal {
    width: 70%;
  }
}

@media screen and (max-width: 426px) and (min-width: 376px) {
  .Modal {
    width: 60%;
  }
}

@media screen and (max-width: 769px) and (min-width: 426px) {
  .Modal {
    width: 60%;
  }
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .Modal {
    width: 45%;
  }
}

@media screen and (max-width: 1441px) and (min-width: 1025px) {
  .Modal {
    width: 30%;
  }
}
