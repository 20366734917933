#modal_login {
  background-color: var(--white);
  width: 458px;
  height: 500px;
  margin-left: 138.5px;
  margin-right: 138.5px;
  margin-top: 32px;
  margin-bottom: 40px;
}

.modal_title {
  background-color: var(--white);
  margin-top: 32px;
}

.title {
  font-weight: bold;
  font-size: 18px;
  /* text-align: left!important; */
}

.modal_body {
  background-color: var(--white);
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal_text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.alert_msg_link:hover {
  text-decoration: none;
}

.modal_alert_msg {
  font-weight: bold;
  color: var(--redContinente);
}

.modal_buttons {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.defaultNegativeButton {
  border: 1px solid gray;
  background-color: var(--white);
  color: var(--black);
  width: 100%;
}

.modal_footer {
  display: block;
}

.mediaButtons {
  display: inline-block;
}

.footerButton {
  background-color: var(--redContinente);
  margin: 2px;
}

.modal_input {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input_text {
  padding-right: 30px;
}

#input_container {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--redContinente);
  position: relative;
}

#label_container {
  position: absolute;
  top: -10px;
  left: 20px;
  background-color: white;
  z-index: 999;
  padding-left: 5px;
  padding-right: 5px;
}

.mail_icon {
  width: 50%;
}

.login_option {
  border: 2px solid lightgray;
  border-radius: 6px;
  padding: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.login_option_text {
  width: 80%;
}

.login_option_img {
  width: 20%;
  text-align: center;
}

.center_aligned {
  text-align: center;
}

.left_aligned {
  text-align: left;
}

.right_aligned {
  text-align: right;
}

.no_border {
  border: 0px solid;
}

.button_small {
  width: 40%;
}
