.form-check-input[type="checkbox"] {
  border-radius: 50%;
  cursor: pointer;
}

.form-check label[for="checkbox"] {
  padding-left: 10px;
}

.form-check-input:checked {
  background-color: var(--redCredentials);
  border-color: var(--redCredentials);
}

.form-check-input.square[type="checkbox"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;
  width: 24px;
  height: 24px;
}

.form-check-input.square.error[type="checkbox"] {
  border: 2px solid var(--redCredentials);
}
