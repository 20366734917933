.faq-page {
}

.faq-container {
  background-color: #ffffff;
  padding: 1rem 30px;
}

.faq-information {
  padding: 15px 25px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 2px solid #ebebeb;
  border-radius: 8px;
}

.faq-information > .question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.faq-information > .question > .text {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.faq-information > .answer {
  height: 0;
  overflow: hidden;
}

.faq-information > .answer > .text {
  font-size: 14px;
  margin-top: 1rem;
}

.faq-information > .question > .faq-chevron-icon {
  color: #eb0203;
  transition: color 0.2s;
}

.faq-information.open > .question > .text {
  font-weight: bold;
}

.faq-information.open > .answer {
  height: 100%;
}

.faq-information.open > .question > .faq-chevron-icon {
  color: #9fa0a0;
}

.faq-perto-webform {
  width: 100%;
  height: 1100px;
}
