.svg-icon-close {
  fill: #eb0203 !important;
  width: 16px;
  height: 16px;
  float: right;
  margin-top: 10px;
  margin-right: 15px;
  cursor: pointer;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.no-padding {
  padding: 0;
}

.no-padding--sides {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 1279.98px) {
  .no-padding-sm-down--sides {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1280px) {
  .no-padding-md-up--left {
    padding-left: 0;
  }

  .no-padding-md-up--right {
    padding-right: 0;
  }

  .footer-copyright-wrapper {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ct-container--px-70 {
    padding: 0 4.375rem;
  }

  .ct-container--px-100 {
    padding: 0 6.25rem;
  }

  .ct-container--my-30 {
    margin: 1.875rem 0;
  }

  .ct-container--py-30 {
    padding: 1.875rem 0;
  }
}

.ct-modal-dialog-secondary-grey2 {
  background-color: #ffffff;
  position: relative;
}

.ct-modal-dialog-secondary-grey2:before {
  background: linear-gradient(transparent, transparent 0),
    linear-gradient(135deg, transparent 33.33%, transparent 0) 0 0,
    transparent linear-gradient(-45deg, #ffffff 33.33%, transparent 0) 0 0;
  background-repeat: repeat-x;
  background-size: 0 100%, 0 0.625rem, 0.8125rem 0.4375rem;
}

.ct-modal-dialog-secondary-grey2:after,
.ct-modal-dialog-secondary-grey2:before {
  content: " ";
  position: absolute;
  top: -0.44rem;
  left: 0;
  display: block;
  height: 1rem;
  width: 100%;
}

.ct-modal-dialog-secondary-grey2:after {
  background: linear-gradient(transparent, transparent 0),
    linear-gradient(135deg, transparent 33.33%, transparent 0) 0 0,
    transparent linear-gradient(45deg, #ffffff 33.33%, transparent 0) 0 0;
  background-repeat: repeat-x;
  background-size: 0 100%, 0 0.625rem, 0.8125rem 0.4375rem;
}

@media (max-width: 767.98px) {
  .footer-copyright-wrapper {
    padding: 23px 15px 29px;
    margin-top: 1.875rem;
    display: block;
    display: block;
    margin: auto 1.25rem auto 0;
    height: auto;
  }
}

.footer-copyright-wrapper {
  padding-top: 7px;
  padding-bottom: 11px;
  display: block !important;
}

@media (min-width: 768px) {
  .footer-copyright-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }
}

.ct-container {
  max-width: 105rem;
  margin: 0 auto;
}

.ct-container--px-70 {
  padding: 0 0.9375rem;
}

.ct-container--px-100 {
  padding: 0 0.9375rem;
}

.ct-container--py-30 {
  padding: 0.9375rem 0;
}

.ct-container--my-30 {
  margin: 0.9375rem 0;
}
