.loading-screen {
  position: fixed;
  width: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.loading-icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
  animation: rotation 1.5s linear infinite;
  color: var(--color-primary);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Loading Desktop*/

.loading-icon.large {
  width: 80px;
  height: 80px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
