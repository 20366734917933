.input_icon_group {
  display: flex;
  align-items: center;
}

.input_icon_group svg {
  cursor: pointer;
}

.input_icon_group .input_icon {
  margin-left: -25px;
}
