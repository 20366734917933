.sc-buttons-primary {
  margin-left: 73.5px;
  margin-right: 73.5px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid var(--redCredentials);
  background-color: var(--redCredentials);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.sc-buttons-primary.sc-buttons-primary-normal {
  width: 228px;
}

.sc-buttons-primary.sc-buttons-primary-large {
  width: 280px;
}

.sc-buttons-primary.sc-buttons-primary-super-large {
  width: 327px;
}

.sc-buttons-primary:hover {
  color: var(--white);
  background-color: var(--darkRedContinente);
  border-color: var(--darkRedContinente);
  opacity: 1;
}

.sc-buttons-primary-text {
  color: var(--white);
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  display: block;
  margin: auto;
  letter-spacing: -0.24px;
}

.sc-buttons-secondary {
  margin-left: 73.5px;
  margin-right: 73.5px;
  margin-top: 20px;
  height: 44px;
  border-radius: 8px;
  border: 2px solid var(--redCredentials);
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.sc-buttons-secondary.sc-buttons-secondary-normal {
  width: 228px;
}

.sc-buttons-secondary.sc-buttons-secondary-large {
  width: 280px;
}

.sc-buttons-secondary.sc-buttons-secondary-super-large {
  width: 327px;
}

.sc-buttons-secondary:hover {
  color: var(--white);
  background-color: var(--redCredentials);
  border-color: var(--redCredentials);
  opacity: 1;
}
.sc-buttons-secondary:hover > * {
  color: var(--white);
}

.sc-buttons-secondary-text {
  color: var(--redCredentials);
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  display: block;
  margin: auto;
  letter-spacing: -0.24px;
}

.sc-buttons-primary:disabled {
  background-color: var(--border-neutral);
  opacity: 1;
  border-radius: 8px;
  border-color: var(--border-neutral);
}

.sc-buttons-primary-normal-text-disabled {
  color: var(--text-btn-disable);
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
