.check-bullet span {
  margin-left: 10px;
}

.check-bullet svg.fa-square path {
  fill: none;
  stroke-width: 30;
  stroke: var(--greyCredentials);
}

.check-bullet svg.fa-check-square path {
  fill: var(--redCredentials);
}
