.option {
  padding: 0px 30px 0px 30px;
  color: black;
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  font-weight: bold;
  padding-top: 17px;
  padding-bottom: 23px;
}

.option .icon {
  width: 24px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.options-group hr {
  border: none;
  height: 1px;
  color: var(--border-neutral);
  background-color: var(--border-neutral);
  margin: 0;
}

.option .arrow {
  margin-left: auto;
}
