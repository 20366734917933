.sc-full-container {
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
}

.sc-cc_max_width {
  width: 354px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 25px 0;
}

@media only screen and (max-width: 576px) {
  .sc-full-container {
    padding-left: 0;
    padding-right: 0;
  }

  .sc-cc_max_width {
    width: 100% !important;
  }
}

.validate-password-forgot {
  color: var(--redContinente);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 130px;
}

.validate-password-forgot.link {
  color: var(--redContinente);
  cursor: pointer;
}

.margin-top-197 {
  margin-top: 197px;
}

.faq-continente-icon {
  height: 16px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.faq-back-icon {
  height: 24px;
  position: relative;
  top: 19.67px;
  left: 24px;
  cursor: pointer;
}

.faq-welcome {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--black);
  font-family: "Open Sans";
  margin-top: 54px;
  margin-left: 24px;
  margin-right: 24px;
}

.c-pointer {
  cursor: pointer !important;
}

.sign-up {
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

.sc-what-is-link {
  height: 19px;
  color: var(--redContinente);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 36px;
  cursor: pointer;
}

#CardAssociation .form-error-message-copy2 {
  height: auto;
}
